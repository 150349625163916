import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import {
  AddBoxOutlined,
  Autorenew,
  EditTwoTone,
  ContactMailTwoTone,
  DeleteTwoTone,
  OpenInNew,
  Sort,
} from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Container, Menu, MenuItem } from '@material-ui/core';

import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { PageListContent } from '../../../Common/styled/PageContent';
import Loading from '../../../Common/Loading';
import { GET_PATIENTS_FOR_PREVIEW } from '../../../../graphql/queries';
import { isEmpty } from '../../../../utils/ObjectUtils';
import { NewPatientDialog } from '../../../Patient/modal/PatientNewDialog';
import { EditPatientDialog } from '../../../Patient/modal/PatientEditDialog';
import { DeletePatientDialog } from '../../../Patient/modal/DeletePatientDialog';
import { InvitePatientDialog } from '../../../Patient/modal/InvitePatientDialog';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { CardWrapperUI } from '../../../Common/styled/CardWrapperUI';
import TableCollapse from '../../../../componentsUI/TableCollapse';
import { PatientManagementDataCollapse } from './PatientManagementDataCollapse';
import { getDateYears } from '../../../../utils/dateTimeUtils';
import { GreyLabel } from '../../../Common/styled/GreyLabel';
import { networkErrorParse } from '../../../../utils/ErrorGraphQLUtils';
import { AlertContainerUI, AlertUI } from '../../../../componentsUI/Alert';
import { IS_REGISTERED } from '../../../../utils/functions';

const fieldNames = [
  { label: 'name', id: 'node.name', width: 150, field: 'NAME', direction: 'ASC' },
  { label: 'surname', id: 'node.surname', width: 150, field: 'SURNAME', direction: 'ASC' },
  { label: null, id: 'node.userUuid', format: IS_REGISTERED, width: 10 },
  { label: 'email', id: 'node.email', width: 210 },
  { label: 'aage', id: 'node.birthDate', width: 35, format: 'YEARS', field: 'SURNAME', align: 'center', direction: 'ASC' },
];

export const PatientManagment = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [refreshNewModal, setRefreshNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { uuid: hospitalUuid } = useSelector((state) => state.hospital);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'SURNAME', text: 'Surname' }],
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_PATIENTS_FOR_PREVIEW, {
      variables: {
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    setServerError(networkErrorParse(error));
  }, [error]);

  const patient = (data && data.patients && selected.length > 0) && data.patients.edges[selected[0]].node;
  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const fetchMorePatients = (fetchMoreCb) => {
    const { endCursor } = data.patients.pageInfo;

    fetchMore({
      query: GET_PATIENTS_FOR_PREVIEW,
      variables: {
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.patients.edges;

        return newEdges.length ? {
          patients: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.patients.__typename,
            totalCount: previousResult.patients.totalCount,
            pageInfo: fetchMoreResult.patients.pageInfo,
            edges: [...previousResult.patients.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.patients.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const isInviteOptionDisabled = (() => {
    if (!patient) return true;
    return !!patient.userUuid;
  })();

  const Age = ({ date }) => (
    <GreyLabel><small>{` - ${getDateYears(date)} ${t('years')}`}</small></GreyLabel>
  );

  const getTitle = (item) => (
    <>
      {`${item.node.name} ${item.node.surname}`}
      <Age date={item.node.birthDate} />
      <small>{item.node.email ? ` - ${item.node.email}` : ''}</small>
    </>
  );

  const getSubTitle = (item) => (
    `${item.node.customId}`
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexPatient = data.patients.edges[index].node;

    navigate(`/patient/${indexPatient.uuid}`);
  };

  const handleOpen = (evt) => {
    evt.stopPropagation();
    navigate(`/patient/${patient.uuid}`);
  };

  const handleCloseNew = () => {
    setShowNewModal(false);
    refetch();
  };

  const handleAddedNew = () => {
    setRefreshNewModal(true);
    setTimeout(() => setRefreshNewModal(false), 250);
    handleCloseNew(false);
  };

  const handleCloseDelete = () => {
    setSelected([]);
    setShowDeleteModal(false);
    refetch();
  };

  const handleCloseInvite = () => {
    setShowInviteModal(false);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    refetch();
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const tableRowViewButton = {
    Icon: OpenInNew,
    tooltip: 'view.patient',
  };

  const buttons = [
    { name: 'new.patient', icon: AddBoxOutlined, handleClick: () => setShowNewModal(true), disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'view.patient', icon: OpenInNew, handleClick: handleOpen, disabled: selected.length !== 1 },
    { name: 'edit.patient', icon: EditTwoTone, I: 'edit', data: patient, handleClick: () => setShowEditModal(true), disabled: selected.length !== 1 },
    { name: 'delete.patient', icon: DeleteTwoTone, I: 'delete', data: patient, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'invite.patient', icon: ContactMailTwoTone, I: 'view', data: patient, handleClick: () => setShowInviteModal(true), disabled: isInviteOptionDisabled },
    { name: 'divider2', type: 'divider' },
    { name: 'refresh', icon: Autorenew, handleClick: () => refetch(), disabled: false },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  return (
    <>
      <Navbar>
        {!refreshNewModal && <NewPatientDialog open={showNewModal} onClose={handleCloseNew} onCreated={handleAddedNew} />}
        <EditPatientDialog open={showEditModal} onClose={handleCloseEdit} patientId={patient && patient.uuid} />
        <DeletePatientDialog open={showDeleteModal} onClose={handleCloseDelete} patient={patient} refetch={refetch} />
        <InvitePatientDialog open={showInviteModal} onClose={handleCloseInvite} patient={patient} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="hospital.settings.patients.manage" items={buttons} />
      </Navbar>
      <Container maxWidth="lg">
        <PageListContent>
          {serverError && isEmpty(data) && (
            <AlertContainerUI>
              <AlertUI title="Error" severity="error">
                {serverError}
              </AlertUI>
            </AlertContainerUI>
          )}
          {loading && isEmpty(data) && <Loading />}
          {!isEmpty(data) && (
            <CardWrapperUI>
              {
                (data && data.patients)
                && (
                  <TableCollapse
                    fieldNames={fieldNames}
                    items={data.patients.edges}
                    GetTitle={getTitle}
                    GetSubtitle={getSubTitle}
                    GetCollapse={PatientManagementDataCollapse}
                    selected={selected}
                    setSelected={setSelected}
                    ActionButtons={tableRowViewButton}
                    handleGoto={handleGoto}
                    isMore={data
                    && data.patients
                    && data.patients.pageInfo
                    && data.patients.pageInfo.hasNextPage}
                    fetchMoreFn={fetchMorePatients}
                    order={{ field: orderByField, direction: orderByDirection }}
                    handleOrder={handleOrderBy}
                  />
                )
              }
            </CardWrapperUI>
          )}
        </PageListContent>
      </Container>
    </>
  );
};
