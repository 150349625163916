import React from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsedItemWrapper } from '../../../Common/styled/CollapsedItemWrapper';
import { GreyLabel } from '../../../Common/styled/GreyLabel';
import { Span } from '../../../Common/styled/Text';
import { Row, Column } from '../../../Common/styled/Groups';
import { ByUser } from '../../../Common/User';
import { getFormattedDate, getFromNowTime } from '../../../../utils/dateTimeUtils';

export const PatientManagementDataCollapse = ({ item }) => {
  const patient = item.node;
  const { t } = useTranslation();

  const registered = !patient.userUuid ? 'patient.not.registered.in.ahp' : 'patient.has.access.to.ahp';
  const invitation = !patient.invitedBy ? t('patient.not.invited.to.join.ahp') : `${t('patient.invited.by')}: `;

  return (
    <CollapsedItemWrapper>
      <Row>
        <Column>
          <Span>
            <GreyLabel>{t(registered)}</GreyLabel>
          </Span>
          <Span>
            <GreyLabel>{invitation}</GreyLabel>
            <ByUser user={patient.invitedBy} />
          </Span>
          <Span>
            <GreyLabel>{`${t('patient.entered.by')}: `}</GreyLabel>
            <ByUser user={patient.createdBy} />
          </Span>
          <Span>
            <GreyLabel>
              {`${t('registered')} ${getFromNowTime({ date: patient.createdAt })} `}
              [
              <small>{` ${getFormattedDate({ date: patient.createdAt })} `}</small>
              ]
            </GreyLabel>
          </Span>
        </Column>
      </Row>
    </CollapsedItemWrapper>
  );
};

export default PatientManagementDataCollapse;
